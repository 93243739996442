import React from 'react';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import Badge, { BadgeVariants } from '@/components/molecules/Badge';
import ClampedText from '@/components/molecules/ClampedText';
import {
  CHOOSE_MODEL,
  STARTING,
  MSRP,
  PRICE_COMING_SOON,
} from '@/lib/constants';
import Image from '@/components/atoms/Image';
import { formatNumberWithCommas } from '@/lib/formatNumberWithCommas';
import { priceFormatter } from '@/lib/priceFormatter';

export interface EVOptionProps {
  ModelId: string;
  Model: string;
  MainImage: string;
  MSRP: number;
}

export interface EVOptionsProps {
  [year: string]: EVOptionProps[];
}

export interface EVCompareModelSelectionProps {
  makeEVOptions: EVOptionsProps;
  selectedYear: string;
  activeModelIndex: number;
  yearList: string[];
  handleBadgeClick: (year: string) => void;
  handleModelClick: (model: string, imageUrl: string, index: number) => void;
  showComparisonDetailPage?: boolean;
  selectedEditEVIndex?: number;
}

export const EVCompareModelSelection: React.FC<
  EVCompareModelSelectionProps
> = ({
  makeEVOptions,
  selectedYear,
  activeModelIndex,
  yearList,
  handleBadgeClick,
  handleModelClick,
  showComparisonDetailPage,
  selectedEditEVIndex,
}) => {
  return (
    <>
      <Heading
        className="mb-[12px] ml-[4px] text-[clamp(1rem,1vw,1.25rem)] l:mb-[24px]"
        level={HeadingLevels.H4}
      >
        {CHOOSE_MODEL}
      </Heading>
      <section>
        {yearList.map((year) => (
          <Badge
            key={year}
            label={year}
            className="mb-[10px] mr-[8px] cursor-pointer text-[12px] md:text-[16px]"
            onClick={() => handleBadgeClick(year)}
            variant={
              selectedYear === year ? BadgeVariants.Blue : BadgeVariants.Gray
            }
          />
        ))}
        <section className="grid grid-cols-2 overflow-scroll pb-[60px] m:grid-cols-4">
          {makeEVOptions[Number(selectedYear)]?.map((model, index) => (
            <div
              className={`align-center m-[4px] flex cursor-pointer flex-col justify-center rounded-[4px] border-[1px] border-solid px-[16px] pb-[12px] ${
                index === activeModelIndex
                  ? 'border-brandSecondary bg-lightTurquoise'
                  : 'border-greyThinBorder'
              }`}
              key={model.ModelId}
              onClick={() =>
                handleModelClick(model.Model, model.MainImage, index)
              }
            >
              <Image
                src={model.MainImage}
                alt="model_image"
                className={`!relative mx-auto ${
                  showComparisonDetailPage ||
                  !model.MainImage ||
                  selectedEditEVIndex === 1
                    ? ''
                    : '-scale-x-100'
                } object-contain`}
                width={200}
                height={200}
                loading="lazy"
              />
              <p className="text-[10px] l:text-[12px]">{model.Model}</p>
              <ClampedText className="text-[10px] text-label l:text-[12px]">
                {model.MSRP === 0
                  ? priceFormatter(model.MSRP, PRICE_COMING_SOON)
                  : `${STARTING} ${MSRP} $${formatNumberWithCommas(
                      model.MSRP
                    )}`}
              </ClampedText>
            </div>
          ))}
        </section>
      </section>
    </>
  );
};
