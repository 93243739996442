import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { FIRST_EV, MSRP, SECOND_EV, STARTING } from '@/lib/constants';
import Image from '@/components/atoms/Image';
import ClampedText from '@/components/molecules/ClampedText';

export interface EVDetailProps {
  make: string;
  year: string;
  model: string;
  trim: string;
  MSRP: number;
  imageUrl: string;
}

interface EVCompareModelHeaderProps {
  modelHeader: string;
  selectedFirstEVStyle?: string;
  selectedSecondEVStyle?: string;
  firstEVIconStyle?: string;
  secondEVIconStyle?: string;
  showComparisonDetailPage?: boolean;
  selectedFirstEVDetails: EVDetailProps;
  selectedSecondEVDetails: EVDetailProps;
}

export const EVCompareModelHeader: React.FC<EVCompareModelHeaderProps> = ({
  modelHeader,
  selectedFirstEVStyle = '',
  firstEVIconStyle = '',
  selectedSecondEVStyle = '',
  secondEVIconStyle = '',
  showComparisonDetailPage,
  selectedFirstEVDetails,
  selectedSecondEVDetails,
}) => {
  return (
    <>
      <Heading
        className="mt-[12px] text-[clamp(1.25rem,1.5vw,1.75rem)] l:mb-[24px]"
        level={HeadingLevels.H4}
      >
        {modelHeader}
      </Heading>
      <section className="mb-[20px] hidden w-full justify-between m:flex">
        <div
          className={`${
            selectedFirstEVDetails.trim ? '!border-solid' : ''
          } ${selectedFirstEVStyle}`}
        >
          {selectedFirstEVDetails.trim ? (
            <div className="fadeIn flex h-full w-full transform">
              <Image
                src={selectedFirstEVDetails.imageUrl}
                alt="model_image"
                className="!relative -scale-x-100 object-contain"
                width={150}
                height={150}
              />
              <div className="align-start flex flex-col justify-center">
                <ClampedText className="whitespace-nowrap text-[10px] text-label l:text-[12px]">
                  {selectedFirstEVDetails.make}
                </ClampedText>
                <p className="text-[10px] l:text-[14px]">
                  {selectedFirstEVDetails.model}
                </p>
                <ClampedText className="text-[10px] text-label l:text-[12px]">
                  {STARTING} {MSRP} $
                  {selectedFirstEVDetails.MSRP.toLocaleString()}
                </ClampedText>
              </div>
            </div>
          ) : (
            <>
              <div className={firstEVIconStyle}>
                <Icon iconId={IconIds.CarToRight} width={20} height={20} />
              </div>
              <span
                className={`text-[clamp(0.6rem,1vw,0.75rem)] ${
                  showComparisonDetailPage ? 'text-light' : 'text-grey'
                }`}
              >
                {FIRST_EV}
              </span>
            </>
          )}
        </div>

        <div
          className={`${
            selectedSecondEVDetails.trim ? '!border-solid' : ''
          } ${selectedSecondEVStyle}`}
        >
          {selectedSecondEVDetails.trim ? (
            <div className="fadeIn flex h-full w-full transform">
              <Image
                src={selectedSecondEVDetails.imageUrl}
                key={selectedSecondEVDetails.imageUrl}
                alt="model_image"
                className="!relative object-contain"
                width={150}
                height={150}
              />
              <div className="align-start flex flex-col justify-center">
                <ClampedText className="whitespace-nowrap text-[10px] text-label l:text-[12px]">
                  {selectedSecondEVDetails.make}
                </ClampedText>
                <p className="text-[10px] l:text-[14px]">
                  {selectedSecondEVDetails.model}
                </p>
                <ClampedText className="text-[10px] text-label l:text-[12px]">
                  {STARTING} {MSRP} $
                  {selectedSecondEVDetails.MSRP.toLocaleString()}
                </ClampedText>
              </div>
            </div>
          ) : (
            <>
              <div className={secondEVIconStyle}>
                <Icon iconId={IconIds.CarToLeft} width={20} height={20} />
              </div>
              <span
                className={`text-[clamp(0.6rem,1vw,0.75rem)] ${
                  showComparisonDetailPage ? 'text-grey' : 'text-light'
                }`}
              >
                {SECOND_EV}
              </span>
            </>
          )}
        </div>
      </section>
    </>
  );
};
