import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import ClampedText from '@/components/molecules/ClampedText';
import useWindowSize from '@/hooks/useWindowSize';
import {
  STARTING,
  MSRP,
  CHOOSE_TRIM,
  PRICE_COMING_SOON,
} from '@/lib/constants';
import { formatNumberWithCommas } from '@/lib/formatNumberWithCommas';
import { priceFormatter } from '@/lib/priceFormatter';
import { useRef, useEffect, useState } from 'react';

export interface TrimListProps {
  ModelId: number;
  MSRP: number;
  Trim: string;
  Model: string;
  Make: string;
  Year: number;
}

export interface EVCompareTrimSelectionProps {
  activeTrimIndex: number;
  trimList: TrimListProps[];
  handleTrimClick: (trim: string, MSRP: number, index: number) => void;
}

export const EVCompareTrimSelection: React.FC<EVCompareTrimSelectionProps> = ({
  trimList,
  activeTrimIndex,
  handleTrimClick,
}) => {
  const { isMobile } = useWindowSize();
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isContentReady, setIsContentReady] = useState(false);
  const [trimStatus, setTrimStatus] = useState<boolean[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function fetchWithRetry(
      trim: TrimListProps,
      retries = 2
    ): Promise<boolean> {
      const response = await fetch(
        `/api/compare/getVehicleStylesData/${trim.Year}/${trim.Make}/${
          trim.Model
        }?trim=${encodeURIComponent(trim.Trim)}`
      );
      if (!response.ok) {
        if (retries > 0) {
          return fetchWithRetry(trim, retries - 1);
        }
        return false;
      }
      return true;
    }

    async function checkTrimList() {
      const trimStatusPromises = trimList.map((trim) => fetchWithRetry(trim));
      const tmpTrimStatus = await Promise.all(trimStatusPromises);
      if (isMounted) {
        setTrimStatus(tmpTrimStatus);
      }
    }

    if (trimList && trimList.length > 0) {
      setIsContentReady(true);
      checkTrimList();
    }

    return () => {
      isMounted = false;
    };
  }, [trimList]);

  useEffect(() => {
    if (isContentReady && sectionRef.current && trimStatus.length > 0) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (trimList.length && activeTrimIndex < 0) {
      handleTrimClick(trimList[0].Trim, trimList[0].MSRP, 0);
    }
  }, [
    isContentReady,
    trimStatus.length,
    handleTrimClick,
    trimList,
    activeTrimIndex,
  ]);

  return (
    <>
      <Heading
        className="mx-[12px] ml-[4px] text-[clamp(1rem,1vw,1.25rem)] l:mb-[24px]"
        level={HeadingLevels.H4}
      >
        {CHOOSE_TRIM}
      </Heading>
      <section>
        <div
          className={`grid overflow-scroll pb-[60px] ${
            isMobile ? 'grid-cols-2' : 'grid-cols-4'
          }`}
          ref={sectionRef}
        >
          {trimList.length === trimStatus.length &&
            trimList.map((trim, index) => {
              return (
                <div
                  className={`align-center m-[4px] flex h-fit-content cursor-pointer flex-col justify-center rounded-[4px] border-[1px] border-solid p-[16px] ${
                    trimStatus[index] ? '' : 'opacity-50'
                  } ${
                    index === activeTrimIndex
                      ? 'border-brandSecondary bg-lightTurquoise'
                      : 'border-greyThinBorder'
                  }`}
                  key={index}
                  onClick={() => handleTrimClick(trim.Trim, trim.MSRP, index)}
                >
                  <p className="text-[10px] l:text-[12px]">{trim.Trim}</p>
                  <ClampedText className="text-[10px] text-label l:text-[12px]">
                    {trim.MSRP === 0
                      ? priceFormatter(trim.MSRP, PRICE_COMING_SOON)
                      : `${STARTING} ${MSRP} $${formatNumberWithCommas(
                          trim.MSRP
                        )}`}
                  </ClampedText>
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
};
